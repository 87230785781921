import Vue from 'vue'
import Vuex from 'vuex'
// upInformation

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '',
    userData: sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')) : {}
  },
  mutations: {
    login(state,data) {
      state.token = data
      sessionStorage.setItem("token", data); 
    },
    logout(state) {
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('userData')
      state.token = ''
      state.userData = ''
    },
    user(state,data) {
      state.userData = data
      sessionStorage.setItem("userData", JSON.stringify(data)); 
    },
  },
  actions: {
  },
  modules: {
  }
})
