<template>
  <div>
    <div class="nav">
      <ul>
        <i @click="operation()" class="iconfont icon-ai-kind"></i>
        <li>
          <a href>首页</a>
        </li>
        <li>
          <a href>AI导航</a>
        </li>
        <li>
          <a href>职业导航</a>
        </li>
        <li>
          <a href>办公导航</a>
        </li>
        <li>
          <a href>工具大全</a>
        </li>
      </ul>
      <div class="more">
        <p @click="shows = !shows">
          更多
          <i class="iconfont icon-xiala"></i>
        </p>
        <div class="cons" v-show="shows">
          <div
            class="list"
            v-for="list in moreList"
            :key="list"
            @click="jump(list)"
          >
            <img :src="list.imgs" alt />
            <span>{{ list.name }}</span>
          </div>
        </div>
      </div>
      <div
        v-show="!$store.state.userData.phone"
        class="icons"
        @click="dialogVisible = true"
      >
        <i class="iconfont icon-icon-person-renwu"></i>
        <p>登录/注册</p>
      </div>
      <div class="icons" v-show="$store.state.userData.phone">
        <i class="iconfont icon-icon-person-renwu"></i>
        <el-dropdown>
          <span class="el-dropdown-link">
            <p>{{ $store.state.userData.phone }}</p>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item @click.native="open()">我的导航</el-dropdown-item> -->
            <el-dropdown-item @click.native="open()">个人中心</el-dropdown-item>
            <el-dropdown-item @click.native="logout()"
              >安全退出</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="banner">
      <div class="search">
        <div class="top">
          <p
            :class="selectIndex == index ? 'xz' : ''"
            v-for="(list, index) in search"
            :key="index"
            @click="checkInfo(list, index)"
          >
            {{ list.title }}
          </p>
        </div>
        <div class="data">
          <input
            id="search-text"
            type="text"
            :placeholder="info.con"
            style="font-size: 15px"
            v-model="content"
            class="form-control smart-tips search-key"
          />
          <i class="iconfont icon-sousuo" @click="go(info.url)"></i>
        </div>
        <div class="bottom">
          <p
            v-for="(list, index) in datas"
            :key="index"
            @click="
              info = list;
              infoIndex = index;
            "
            :class="infoIndex == index ? 'xz2' : ''"
          >
            {{ list.title }}
          </p>
          {{ id }}
        </div>
      </div>
    </div>
    <v-login :dialogVisible="dialogVisible" @outClose="dialogVisible = false" />

    <el-dialog
      title="我的导航"
      :visible.sync="navDialogVisible"
      :before-close="outClose"
      width="880px"
    >
      <div class="search">
        <div class="left">
          <div class="box">
            <el-input
              placeholder="请输入内容"
              style="width: 300px"
              v-model="title"
              clearable
              class="input-with-select"
            >
              <template slot="prepend">标题名称</template>
            </el-input>
            <el-button type @click="getList()">搜索数据</el-button>
            <el-button type="primary" @click="dataDialogVisible = true"
              >新增导航</el-button
            >
          </div>
        </div>
      </div>

      <div class="table">
        <el-table
          :data="tableData"
          style="width: 100%"
          header-cell-style="background: #f5f7fa;"
        >
          <el-table-column
            align="center"
            prop="title"
            :show-overflow-tooltip="true"
            label="导航名称"
            fixed="left"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="url"
            label="链接"
            width="200"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="address"
            label="操作"
            fixed="right"
          >
            <template slot-scope="scope">
              <div class="flex">
                <el-tooltip
                  class="item"
                  @click.native="jump(scope.row)"
                  effect="dark"
                  :content="'链接到' + scope.row.title"
                  placement="top"
                >
                  <p class="table-btn">
                    <i class="el-icon-s-promotion"></i>
                  </p>
                </el-tooltip>

                <el-tooltip
                  class="item"
                  @click.native="editProduct(scope.row)"
                  effect="dark"
                  content="编辑"
                  placement="top"
                >
                  <p class="table-btn">
                    <i class="el-icon-edit"></i>
                  </p>
                </el-tooltip>

                <el-tooltip
                  class="item"
                  @click.native="delList(scope.row.id)"
                  effect="dark"
                  content="删除"
                  placement="top"
                >
                  <p class="table-btn">
                    <i class="el-icon-delete-solid"></i>
                  </p>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChange"
            :page-sizes="[10, 50, 100, 150, 200]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
      <template #footer>
        <div type="flex" slot="footer" class="dialog-footer">
          <el-button type="info" @click="navDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="navDialogVisible = false"
            >确 定</el-button
          >
        </div>
      </template>
    </el-dialog>

    <el-dialog
      :title="con.id ? '编辑导航' : '添加导航'"
      :visible.sync="dataDialogVisible"
      width="500px"
    >
      <div class="from">
        <div class="list-data">
          <div class="list-dialog">
            <div class="list" style="width: 100%">
              <p style="margin-bottom: 10px">
                导航名称
                <span style="color: red; margin-left: 0px; font-weight: 500"
                  >*</span
                >
              </p>
              <el-input
                v-model="con.title"
                placeholder="请输入导航名称"
              ></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list" style="width: 100%">
              <p style="margin-bottom: 10px">
                跳转链接
                <span style="color: red; margin-left: 0px; font-weight: 500"
                  >*</span
                >
              </p>
              <el-input
                v-model="con.url"
                placeholder="请输入跳转链接"
              ></el-input>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div type="flex" slot="footer" class="dialog-footer">
          <el-button type="info" @click="dataDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submit()">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getSearch, getSearchInfo, searchList } from "@/api/search";
import { getMoreList } from "@/api/data";
import { addList, updateList, getList, deleteList } from "@/api/user";
export default {
  data() {
    return {
      info: {},
      con: {},
      search: [],
      datas: [],
      tableData: [],
      moreList: [],
      selectIndex: 0,
      infoIndex: 0,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      content: "",
      ip: "",
      title: "",
      shows: false,
      dialogVisible: false,
      navDialogVisible: false,
      dataDialogVisible: false,
    };
  },
  watch: {
    dataDialogVisible(val) {
      if (!val) {
        this.con = {};
      }
    },
    shows(val) {
      if (val) {
        var that = this;
        getMoreList().then((res) => {
          if (res.code == 200) {
            this.moreList = res.data;
          }
        });
        var times = setInterval(function () {
          that.$nextTick(function () {
            that.shows = false;
          });
          clearInterval(times);
        }, 3000);
      }
    },
    navDialogVisible(val) {
      if (val) {
        this.getList();
      }
    },
  },
  created() {
    this.getSearch();
  },
  methods: {
    editProduct(list) {
      this.con = JSON.parse(JSON.stringify(list));
      this.dataDialogVisible = true;
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },
    pageChange(e) {
      this.pageNum = e;
      this.getList();
    },
    getList() {
      getList(this.pageNum, this.pageSize, this.title).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    submit() {
      if (this.con.id) {
        updateList(this.con.id, this.con).then((res) => {
          if (res.code == 200) {
            this.getList();
            this.$message.success("编辑成功");
            this.dataDialogVisible = false;
          }
        });
      } else {
        addList(this.con).then((res) => {
          if (res.code == 200) {
            this.getList();
            this.$message.success("添加成功");
            this.dataDialogVisible = false;
          }
        });
      }
    },
    delList(id) {
      deleteList(id).then((res) => {
        if (res.code == 200) {
          this.getList();
          this.$message.success("删除成功");
        }
      });
    },
    open() {
      // this.navDialogVisible = true;
      this.$router.push("/collect");
    },
    jump(list) {
      window.open(list.url);
      this.shows = false;
    },
    operation() {
      this.$emit("operation");
    },
    go(url) {
      this.ipdizhi();
      if (!this.content) {
        return this.$message.error("请输入搜索内容");
      }

      searchList({
        title: this.content,
        ip: this.ip,
        type: this.info.title,
      }).then((res) => {
        if (res.code == 200) {
          window.open(url + this.content);
        }
      });
    },
    checkInfo(list, index) {
      this.getSearchInfo(list.id);
      this.selectIndex = index;
    },
    getSearch() {
      getSearch().then((res) => {
        if (res.code == 200) {
          this.search = res.data.list;
          this.getSearchInfo(res.data.list[0]["id"]);
        }
      });
    },
    getSearchInfo(id) {
      getSearchInfo(id).then((res) => {
        if (res.code == 200) {
          this.datas = res.data.list;
          this.info = res.data.list[this.infoIndex];
        }
      });
    },
    ipdizhi() {
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => {
          this.ip = data.ip;
        });
    },
    logout() {
      this.$store.commit("logout");
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  margin-bottom: 20px;
  .el-input {
    margin-right: 20px;
  }
}
.nav {
  width: 90%;
  height: 60px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  ul {
    width: 87%;
    display: flex;
    li {
      list-style: none;
      height: 50px;
      line-height: 50px;
      margin-right: 40px;
      font-size: 15px;
      a {
        text-decoration: none;
        color: #eee;
      }
    }
    i {
      color: #fff;
      font-size: 23px;
      margin-right: 30px;
      height: 50px;
      line-height: 50px;
      cursor: pointer;
    }
  }
  .more {
    width: 100px;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    font-size: 16px;
    position: relative;
    p {
      cursor: pointer;
    }
    .cons {
      width: 250px;
      height: auto;
      background: #fff;
      border-radius: 4px;
      position: absolute;
      top: 30px;
      left: -170px;
      padding-top: 20px;
      padding-bottom: 0px;
      padding-left: 10px;
      padding-right: 10px;
      .list {
        width: 25%;
        display: list-item;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        float: left;
        margin-bottom: 20px;
        img {
          height: 25px;
        }
        span {
          color: #000;
          font-size: 13px;
          margin-top: 10px;
          display: block;
        }
      }
    }
  }
  .icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    i {
      display: block;
      font-size: 20px;
      color: #fff;
      cursor: pointer;
    }
    p {
      color: #fff;
      margin-left: 5px;
      font-size: 15px;
      cursor: pointer;
    }
    .el-dropdown-link {
      display: flex;
      cursor: pointer;
    }
  }
}
.banner {
  width: 100%;
  height: 450px;
  background: url("../../../assets/1.jpeg") no-repeat;
  background-size: cover;
  background-position: center;
  .search {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .top {
      display: flex;
      margin-bottom: 20px;
      p {
        color: #fff;
        opacity: 0.6;
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .data {
      position: relative;
      #search-text {
        width: 800px;
        height: 35px;
        border-radius: 50px;
        color: #fff;
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 9px 20px;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        background-color: rgba(0, 0, 0, 0.6) !important;
        outline: none;
      }
      i {
        font-size: 20px;
        color: #fff;
        font-weight: 800;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
    .bottom {
      display: flex;
      margin-top: 15px;
      p {
        color: #fff;
        opacity: 0.6;
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
        font-size: 15px;
      }
    }
  }
}
.xz {
  color: #fff !important;
  opacity: 1 !important;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 3px;
    border-radius: 10px;
    background: #fff;
    left: 50%;
    transform: translateX(-50%);
    bottom: -8px;
  }
}

.xz2 {
  color: #fff !important;
  opacity: 1 !important;
}
/deep/ .el-dialog__body {
  padding: 10px 20px !important;
}
.table {
}

.list-data {
  width: 100%;
  margin-top: 0px;
  h3 {
    width: 100%;
    height: 45px;
    line-height: 45px;
    background: #f57a3d;
    color: #fff;
    border-radius: 3px;
    text-indent: 10px;
    font-size: 15px;
    margin-bottom: 20px;
  }
  .list-dialog {
    width: 95%;
    margin: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .list {
      width: 310px;
      margin-bottom: 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        width: 100%;
      }
      .el-date-picker {
        width: 100%;
      }
    }
  }
}
</style>