import request from '@/utils/request'

export function searchList(data) {
    return request({
        url: '/home/searchList',
        method: 'post',
        data
    })
}

export function getSearch() {
    return request({
        url: '/home/getSearch',
        method: 'get'
    })
}

export function getSearchInfo(id) {
    return request({
        url: '/home/getSearchInfo?id=' + id,
        method: 'get'
    })
}


export function getByList(yid) {
    return request({
        url: '/Searchs/getByList?yid=' + yid,
        method: 'get'
    })
}
