import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import moment from 'moment';
import qs from 'qs'
Vue.prototype.$qs = qs
Vue.prototype.$axios = axios
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
import Vue2Editor from "vue2-editor";
// import rem from '@/utils/rem.js'
// import 'lib-flexible/flexible.js'
// Vue.config.productionTip = false
// Vue.use(rem);
Vue.use(Vue2Editor);
Vue.use(ElementUI);
import VScaleScreen from 'v-scale-screen'
Vue.use(VScaleScreen)

import login from '@/components/login'
Vue.component('v-login',login)

Vue.prototype.$formattedDate = function(data,format) {
  return moment(data).format(format);
}

Vue.prototype.$moneyFormat = function(data) {
  if(data.toString().length > 1000000) {
    return data.toFixed(2)
  }
}


Vue.prototype.$to = function(name,params) {
	router.push({
    name: name,
    params: params
  })
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
