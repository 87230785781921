<template>
  <div id="app">
    <router-view v-if="routerAlive"></router-view>
  </div>
</template>

<script>
// import { getStatus } from "./api/machine";
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      routerAlive: true
    };
  },
  methods: {
    reload() {
      this.routerAlive = false;
      this.$nextTick(function() {
        this.routerAlive = true;
      });
    }
  },
  created() {
  }
};
</script>

<style lang="less">
@import url('./font/font.css');
@font-face {
  font-family: "SYH";
  src: url("./font/font.otf");
  font-weight: normal;
  font-style: normal;
}

.tab {
  width: 1200px;
  height: 50px;
  margin: auto;
  display: flex;
  align-items: center;
}

h1 {
  font-size: 25px;
}

.tab-title {
  height: 50px;
  font-weight: 500;
  font-size: 15px;
}

.table-btn {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background: #ebeef4;
  color: #60618a;
  cursor: pointer;
  transition: all 0.3s;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-tooltip {
    margin-left: 5px;
    margin-right: 5px;
  }
}

// .nav {
//   width: 100%;
//   height: auto;
//   display: flex;
//   align-items: center;
//   margin-bottom: 15px;
//   h1 {
//     font-size: 20px;
//   }
//   .el-tag {
//     margin-left: 10px;
//     background: #f57a3d24;
//     color: #f57a3d;
//     font-weight: 500;
//     margin-right: 20px;
//   }
//   p {
//     color: #f57a3d;
//     font-weight: 600;
//     font-size: 16px;
//     margin-left: 20px;
//     cursor: pointer;
//   }
// }

.el-button--text,
.el-time-panel__btn.confirm,
.el-date-table td.today span {
  color: #f57a3d !important;
}


.el-date-picker__header-label.active, .el-date-picker__header-label:hover,
.el-date-picker__header-label.active, .el-date-picker__header-label:hover {
  color: #f57a3d !important;
}

.el-switch.is-checked .el-switch__core {
  border-color: #f57a3d !important;
  background-color: #f57a3d !important;
}

// .el-date-table td.today span {
//   color: #f57a3d !important;
// }

.el-button.is-plain:focus,
.el-button.is-plain:hover {
  color: #fff !important;
  border-color: #f57a3d !important;
  background-color: #f57a3d !important;
}

.el-date-table td.current:not(.disabled) span {
  background-color: #f57a3d !important;
}

.el-date-table td.available:hover {
  color: #f57a3d !important;
}

.el-select-dropdown__item.selected {
  color: #f57a3d !important;
}
.el-radio.is-bordered.is-checked {
  border-color: #f57a3d !important;
}

.el-radio__input.is-checked + .el-radio__label {
  color: #f57a3d !important;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #f57a3d !important;
  background-color: #f57a3d !important;
}

// .el-table .ascending .sort-caret.ascending {
//     border-top-color: #F57A3D !important;
// }

.el-table .ascending .sort-caret.ascending {
  border-bottom-color: #f57a3d !important;
}

.el-table .descending .sort-caret.descending {
  border-top-color: #f57a3d !important;
}

// .el-table .descending .sort-caret.descending {
//     border-bottom-color: #F57A3D !important;
// }

// .el-table .ascending .sort-caret.ascending {
//   border-bottom-color: #F57A3D !important;
// }

* {
  padding: 0;
  margin: 0;
  font-family: "SYH";
}
body {
  min-width: 1300px;
  // background: #f5f6f7;
}
.pagination {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}


// .el-button:focus, .el-button:hover {
//   color: #f57a3d !important;
//   background: #fef2ec !important;
//   border-color: #fbcab1 !important;
// }
</style>