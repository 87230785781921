import request from '@/utils/request'


export function login(data) {
    return request({
        url: '/user/login', 
        method: 'post',
        data
    })
}

export function reg(data) {
    return request({
        url: '/user/register', 
        method: 'post',
        data
    })
}

export function info(data) {
    return request({
        url: '/info/index', 
        method: 'post',
        data
    })
}

export function getList(pageNum,pageSize,title) {
    return request({
        url: '/info/getList?pageNum=' + pageNum + '&pageSize=' + pageSize + '&title=' + title, 
        method: 'get'
    })
}


export function addList(data) {
    return request({
        url: '/info/addList', 
        method: 'post',
        data
    })
}

export function updateList(id,data) {
    return request({
        url: '/info/updateList?id=' + id, 
        method: 'post',
        data
    })
}

export function deleteList(id) {
    return request({
        url: '/info/deleteList?id=' + id, 
        method: 'post',
    })
}



export function logout() {
    return request({
        url: '/auth/logout', 
        method: 'delete'
    })
}

export function code() {
    return request({
        url: '/code', 
        method: 'get'
    })
}