<template>
  <div>
    <div class="fast">
      <div
        class="list"
        style="background: url('http://guozhivip.com/images/b1.jpg') no-repeat;background-size: cover;"
      >
        <p>AI</p>
      </div>
      <div
        class="list"
        style="background: url('http://guozhivip.com/images/r7.jpg') no-repeat;background-size: cover;"
      >
        <p>电影</p>
      </div>
      <div
        class="list"
        style="background: url('http://guozhivip.com/images/b2.jpg') no-repeat;background-size: cover;"
      >
        <p>游戏</p>
      </div>
      <div
        class="list"
        style="background: url('http://guozhivip.com/images/b4.jpg') no-repeat;background-size: cover;"
      >
        <p>商城比价</p>
      </div>
      <div
        class="list"
        style="background: url('http://guozhivip.com/images/b5.jpg') no-repeat;background-size: cover;"
      >
        <p>抢茅台</p>
      </div>
      <div
        class="list"
        style="background: url('http://guozhivip.com/images/r8.jpg') no-repeat;background-size: cover;"
      >
        <p>论坛</p>
      </div>
      <div
        class="list"
        style="background: url('http://guozhivip.com/images/r4.jpg') no-repeat;background-size: cover;"
      >
        <p>租房信息</p>
      </div>
    </div>
    <div class="datas">
      <!-- <div class="user" v-show="$store.state.userData.phone">
        <h2>
          我的导航
          <span @click="dialogVisible = true">添加导航</span>
        </h2>
        <div class="list">
          <div class="list-a" v-for="(item,index) in list" :key="index"  @click="go(item)">
            <p>{{item.title}}</p>
          </div>
        </div>
      </div>-->
      <div class="item">
        <div class="list" v-for="(list,index) in data" :key="index">
          <h2>
            <img v-if="list.imgs" :src="list.imgs" alt />
            {{list.name}}
            <a>更多></a>
          </h2>
          <p>
            <a
              v-for="(v,key) in list.lists"
              :key="key"
              :href="v.url"
              target="_blank"
              :style="'color:' + v.color"
            >
              <img :src="v.imgs" alt />
              {{v.name}}
              <i v-if="v.cons" class="iconfont icon-xiala"></i>
              <ul class="ulse" v-if="v.cons">
                <li class="li" v-for="(items,keys) in v.cons" :key="keys">
                  <a :href="items.url"  target="_blank">{{items.name}}</a>
                </li>
              </ul>
            </a>
          </p>
        </div>
      </div>
    </div>

    <el-dialog :title="con.id ? '编辑导航': '添加导航'" :visible.sync="dialogVisible" width="500px">
      <div class="from">
        <div class="list-data">
          <div class="list-dialog">
            <div class="list" style="width:100%">
              <p style="margin-bottom: 10px;">
                导航名称
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input v-model="con.title" placeholder="请输入导航名称"></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list" style="width:100%">
              <p style="margin-bottom: 10px;">
                跳转链接
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input v-model="con.url" placeholder="请输入跳转链接"></el-input>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div type="flex" slot="footer" class="dialog-footer">
          <el-button type="info" @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit()">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { addList, getList } from "@/api/user";
export default {
  props: ["data"],
  inject: ["reload"],
  data() {
    return {
      con: {},
      list: [],
      dialogVisible: false
    };
  },
  watch: {
    "$store.state.userData": {
      deep: true,
      handler(val) {
        if (val) {
          this.getList();
        }
      }
    },
    dialogVisible(data) {
      if (!data) {
        this.con = {};
      }
    }
  },
  methods: {
    go(list) {
      window.open(list.url);
    },
    getList() {
      getList().then(res => {
        if (res.code == 200) {
          this.list = res.data;
        }
      });
    },
    submit() {
      addList(this.con).then(res => {
        if (res.code == 200) {
          this.getList();
          this.$message.success("添加成功");
          this.dialogVisible = false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0px 20px;
}
/deep/ .el-dialog__header {
  padding: 10px 10px 10px;
  border-bottom: 1px solid #f5f6f7;
}
/deep/ .el-dialog__headerbtn {
  top: 15px;
}

/* 弹出框居中显示 */
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
}
/* 弹出框超出部分滑动 */
/deep/ .el-dialog__body {
  overflow: hidden;
  overflow-y: auto;
}
/deep/ .el-radio {
  margin-right: 0px;
}

/deep/ .el-select .el-input {
  width: 100%;
}

.list-data {
  width: 100%;
  margin-top: 20px;
  h3 {
    width: 100%;
    height: 45px;
    line-height: 45px;
    background: #f57a3d;
    color: #fff;
    border-radius: 3px;
    text-indent: 10px;
    font-size: 15px;
    margin-bottom: 20px;
  }
  .list-dialog {
    width: 95%;
    margin: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .list {
      width: 310px;
      margin-bottom: 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        width: 100%;
      }
      .el-date-picker {
        width: 100%;
      }
    }
  }
}

.fast {
  width: 1400px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -60px;
  .list {
    width: 13.5%;
    height: 95px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    p {
      color: #fff;
    }
  }
}
.datas {
  margin-top: -30px;
}
.user {
  width: 95%;
  margin: auto;
  margin-bottom: 20px;
  h2 {
    height: 50px;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    text-indent: 10px;
    &::after {
      content: "";
      position: absolute;
      width: 4px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%);
      background: #409eff;
    }
    span {
      font-size: 13px;
      color: #fff;
      cursor: pointer;
      margin-left: 30px;
      width: 90px;
      height: 35px;
      line-height: 35px;
      background: #409eff;
      text-align: center;
      border-radius: 3px;
      text-indent: 0px;
    }
  }
  .data {
    width: 100%;
    height: 100px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 50px;
  }
  .list {
    display: flex;
    p {
      font-size: 15px;
      cursor: pointer;
      margin-bottom: 10px;
      color: rgba(0, 0, 0, 0.7);
      margin-right: 20px;
    }
  }
}
.item {
  width: 1400px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .list {
    width: 32.5%;
    margin-bottom: 20px;
    h2 {
      height: 30px;
      font-size: 16px;
      margin-bottom: 10px;
      padding-bottom: 5px;
      border-bottom: 1px dashed #ddd;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      a {
        font-size: 14px;
        color: #000;
        opacity: 0.5;
        cursor: pointer;
        position: relative;
        top: 0px;
        // right: 10px;
        left: 15px;
      }
      img {
        height: 10px;
        margin-right: 10px;
      }
    }
    p {
      height: auto;
      a {
        width: 33%;
        display: block;
        float: left;
        font-size: 15px;
        line-height: 30px;
        cursor: pointer;
        margin-bottom: 3px;
        // opacity: 0.8;
        // color: #000;
        color: rgba(0, 0, 0, 0.7);
        text-decoration: none;
        display: flex;
        align-items: center;
        opacity: 0.3s;
        position: relative;
        img {
          height: 15px;
          margin-right: 5px;
        }
        &:hover {
          color: #f57a3d;
          // font-weight: 800;
          text-decoration: block;
        }
        .ulse {
          position: absolute;
          width: 170px;
          height: auto;
          background: #f5f6f7;
          top: 30px;
          z-index: 9;
          display: flex;
          flex-direction: column;
          border-top: 1px solid #ccc;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc;
          display: none;
          .li {
            width: 100%;
            height: 36px;
            // line-height: 36px;
            list-style: none;
            color: #000;
            border-bottom: 1px solid #ccc;
            display: flex;
            a {
              text-decoration: none;
              color: #000;
              text-indent: 20px;
              font-size: 13px;
              margin-bottom: 0px;
              width: 100%;
              &:hover {
                color: red;
                font-weight: 800;
              }
            }
          }
        }
      }
     
      span {
        width: 33%;
        display: block;
        float: left;
        font-size: 15px;
        cursor: pointer;
        margin-bottom: 10px;
        opacity: 0.8;
        color: #000;
        opacity: 0.6;
      }

      a:hover .ulse{
        display: block;
      }
    }
  }
}
</style>
