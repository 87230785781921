<template>
  <div class="cons">
    <div style="height: 80px"></div>
    <div class="content">
      <div class="left">
        <ul>
          <li
            v-for="(list, index) in navList"
            :key="index"
            @click="
              jump(list.path);
              navIndex = index;
            "
            :class="navIndex == index ? 'xz' : ''"
          >
            {{ list.name }}
          </li>
          <!-- <li>我的收藏</li>
                  <li>购买记录</li>
            <li>我的积分</li>-->
        </ul>
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
    <v-footer />
  </div>
</template>
  
  <script>
import footer from "@/components/footer";
export default {
  components: {
    "v-footer": footer,
  },
  data() {
    return {
      navIndex: 0,
      navList: [
        // {
        //   name: "我的首页",
        //   path: "/info",
        // },
        {
          name: "我的导航",
          path: "/collect",
        },
        {
          name: "我的发布",
          path: "/integral"
        }
      ],
      tableList: [],
    };
  },
  watch: {
    "$store.state.token": {
      handler(val) {
        if (!val) {
          this.$router.push("/");
        }
      },
    },
  },
  created() {
    if (!this.$store.state.token) {
      this.$router.push("/");
    }
  },
  methods: {
    jump(url) {
      this.$router.push({
        name: url,
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
  .cons {
    background: #f5f6f7;
  }
.content {
  width: 1200px;
  height: auto;
  min-height: 600px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  .left {
    width: 18%;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    background: #fff;
    ul {
      li {
        height: 45px;
        line-height: 45px;
        list-style: none;
        text-indent: 20px;
        cursor: pointer;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
      }
      .xz {
        color: #3363ff;
        background: #ebefff;
      }
    }
  }
  .right {
    width: 80%;
    height: auto;
    padding-top: 10px;
    background: #fff;
  }
}
</style>