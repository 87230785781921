import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index/index.vue'
import user from '../views/user/index.vue'
// import store from '../store/index'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: '/index',
        component: resolve => require(['../views/index/index.vue'], resolve),
        meta: { requireAuth: true },
      }]
  },
  {
    path: '/details',
    name: '/details',
    component: resolve => require(['../views/index/details.vue'], resolve),
    meta: { requireAuth: true },
  },
  {
    path: '/file-details',
    name: '/file-details',
    component: resolve => require(['../views/index/file.vue'], resolve),
    meta: { requireAuth: true },
  },
  {
    path: '/direct',
    name: '/direct',
    component: resolve => require(['../views/direct/index.vue'], resolve),
    meta: { requireAuth: true },
  },
  {
    path: '/about',
    name: '/about',
    component: resolve => require(['../views/about/index.vue'], resolve),
    meta: { requireAuth: true },
  },
  {
    path: '/film',
    name: '/film',
    component: resolve => require(['../views/film/index.vue'], resolve),
    meta: { requireAuth: true },
  },{
    path: '/user',
    name: '/user',
    component: user,
    children: [
      {
        path: '/info',
        name: '/info',
        component: resolve => require(['../views/user/info.vue'], resolve),
        meta: { requireAuth: true },
      },
      // {
      //   path: '/integral',
      //   name: '/integral',
      //   component: resolve => require(['../views/user/integral.vue'], resolve),
      //   meta: { requireAuth: true },
      // },
      {
        path: '/collect',
        name: '/collect',
        component: resolve => require(['../views/user/collect.vue'], resolve),
        meta: { requireAuth: true },
      },
      // {
      //   path: '/buy',
      //   name: '/buy',
      //   component: resolve => require(['../views/user/buy.vue'], resolve),
      //   meta: { requireAuth: true },
      // },
      // {
      //   path: '/download',
      //   name: '/download',
      //   component: resolve => require(['../views/user/download.vue'], resolve),
      //   meta: { requireAuth: true },
      // }
    ]
    // component: resolve => require(['../views/index/index.vue'], resolve),
    // meta: { requireAuth: true },
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
