<template>
  <div>
    <div class="con">
      <h3>福利之家导航<br>上网从这里开始</h3>
      <p>小提示：按下 Ctrl+D 可收藏本网页，方便下一次快速打开使用</p>
      <div class="cons">
        <a href="#">网址提交</a>
        <span>|</span>
         <a href="#">推广合作</a>
      </div>
    </div>
    <div class="footer">
      <div class="top">
        <div class="url">
          <h3>友情链接</h3>
          <ul>
            <li>
              <a href="#">兔展H5营销制作</a>
            </li>
            <li>
              <a href="#">ACG门户</a>
            </li>
            <li>
              <a href="#">技术拉近你我</a>
            </li>
            <li>
              <a href="#">自由钢琴</a>
            </li>
            <li>
              <a href="#">盘搜</a>
            </li>
            <li>
              <a href="#">兔展H5营销制作</a>
            </li>
            <li>
              <a href="#">ACG门户</a>
            </li>
            <li>
              <a href="#">技术拉近你我</a>
            </li>
            <li>
              <a href="#">自由钢琴</a>
            </li>
            <li>
              <a href="#">盘搜</a>
            </li>
          </ul>
        </div>
        <div class="data">
          <h3>旗下站点</h3>
          <ul>
            <li>
              <a href="#">商城比较</a>
            </li>
            <li>
              <a href="#">抢茅台</a>
            </li>
            <li>
              <a href="#">租房信息</a>
            </li>
            <li>
              <a href="#">论坛</a>
            </li>
          </ul>
        </div>
        <div class="about">
          <h3>关于我们</h3>
          <ul>
            <li>
              <a href="#">网址提交</a>
            </li>
            <li>
              <a href="#">联系我们</a>
            </li>
            <li>
              <a href="#">合作</a>
            </li>
            <li>
              <a href="#">意见反馈</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="bootom">版权所有©2023 福利之家导航 </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.con {
  width: 100%;
  height: 350px;
  background: url('http://guozhivip.com/images/largebg.jpg') no-repeat;
  background-size: cover;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h3 {
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-weight: 800;
    border-bottom: 1px solid #fff;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
    padding-bottom: 20px;
  }
  p {
    text-align: center;
    color: #fff;
    opacity: 0.9;
    margin-top: 10px;
    font-size: 13px;
  }
  .cons {
    display: flex;
    margin-top: 10px;
    a {
      color: #fff;
      font-weight: 700;
      font-size: 14px;
    }
    span {
      display: block;
      color: #fff;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
.footer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
  .top {
    width: 1400px;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 5px;
    .url {
      width: 40%;
      h3 {
        color: #fff;
        opacity: 0.9;
        margin-bottom: 5px;
        font-size: 16px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          list-style: none;
          margin-right: 16px;
          margin-bottom: 5px;
          a {
            text-decoration: none;
            color: #fff;
            opacity: 0.6;
            font-size: 14px;
          }
        }
      }
    }
    .data {
      width: 20%;
      h3 {
        color: #fff;
        opacity: 0.9;
        margin-bottom: 5px;
        font-size: 16px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          list-style: none;
          margin-right: 16px;
          margin-bottom: 5px;
          a {
            text-decoration: none;
            color: #fff;
            opacity: 0.6;
            font-size: 14px;
          }
        }
      }
    }
    .about {
      width: 30%;
      h3 {
        color: #fff;
        opacity: 0.9;
        margin-bottom: 5px;
        font-size: 16px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          list-style: none;
          margin-right: 16px;
          margin-bottom: 5px;
          a {
            text-decoration: none;
            color: #fff;
            opacity: 0.6;
            font-size: 14px;
          }
        }
      }
    }
  }
  .bootom {
    width: 1400px;
    height: 50px;
    line-height: 50px;
    margin: auto;
    text-align: center;
    color: #fff;
    opacity: 0.5;
    font-size: 13px;
  }
  // .bootom {
  //   width: 90%;
  //   margin: auto;
  // }
}
</style>