<template>
  <div>
    <el-dialog
      :title="IsLogin ? '登录' : '注册'"
      :visible.sync="dialogVisible"
      :before-close="outClose"
      width="500px"
    >
      <div class="from" v-show="IsLogin">
        <div class="list-data">
          <div class="list-dialog">
            <div class="list" style="width:100%">
              <p style="margin-bottom: 10px;">
                手机号
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input v-model="phone" placeholder="请输入手机号"></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list" style="width:100%">
              <p style="margin-bottom: 10px;">
                密码
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input v-model="password" type="password" placeholder="请输入密码"></el-input>
            </div>
          </div>
        </div>
        <p class="code">
          还没有账号密码？
          <span @click="IsLogin = false">前去注册</span>
        </p>
      </div>

      <div class="from" v-show="!IsLogin">
        <div class="list-data">
          <div class="list-dialog">
            <div class="list" style="width:100%">
              <p style="margin-bottom: 10px;">
                手机号
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input v-model="phone" placeholder="请输入手机号"></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list" style="width:100%">
              <p style="margin-bottom: 10px;">
                密码
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input v-model="password" type="password" placeholder="请输入密码"></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list" style="width:100%">
              <p style="margin-bottom: 10px;">
                确认密码
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input v-model="password2" type="password" placeholder="请再次输入密码"></el-input>
            </div>
          </div>
        </div>
        <p class="code">
          已有账号？
          <span @click="IsLogin = true">前去登录</span>
        </p>
      </div>

      <template #footer>
        <div type="flex" slot="footer" class="dialog-footer">
          <el-button type="info" @click="outClose()">取 消</el-button>
          <el-button type="primary" @click="submit()">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { login,reg,info } from "@/api/user";
export default {
  props: {
    con: {
      type: Object,
      default: () => {}
    },
    typeList: {
      type: Array,
      default: () => []
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      logoHide: false,
      IsLogin: true,
      phone: "",
      password: "",
      password2: "",
      uploadHeaders: {
        Authorization: "Bearer" + " " + sessionStorage.getItem("access_token")
      }
    };
  },
  watch: {
    IsLogin() {
      this.phone = "";
      (this.password = ""), (this.password2 = "");
    },
    dialogVisible(data) {
      if (!data) {
        this.phone = "";
        (this.password = ""), (this.password2 = "");
      }
    }
  },
  created() {
    // this.getList();
  },
  methods: {
    logoUploadSuccess(res) {
      this.$refs.logoImage.clearFiles();
      if (res.code == 200) {
        this.con.imgs = "http://api.nav990.com/" + res.data;
      } else {
        this.$message.error(res.msg);
      }
      this.$forceUpdate();
    },

    logoRemove() {
      this.con.imgs = "";
      this.$forceUpdate();
    },

    submit() {
      if (!this.IsLogin) {
        reg({
          phone: this.phone,
          password: this.password,
          password2: this.password2
        }).then(res => {
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.IsLogin = true;
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        login({
          phone: this.phone,
          password: this.password
        }).then(res => {
          if (res.code == 200) {
            this.$store.commit("login", res.token);
            this.info()
            this.outClose()
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    info() {
        info().then(res => {
            if(res.code == 200) {
              this.$store.commit("user", res.data);
              // location.reload(); // 待优化
            }
        })
    },
    // async add() {
    //   await addData({
    //     con: this.con.con,
    //     title: this.con.title,
    //     type: this.con.type,
    //     url: this.con.url
    //   }).then(res => {
    //     if (res.code == 200) {
    //       this.$message.success(res.msg);
    //       this.$emit("outClose");
    //     }
    //   });
    // },
    // async edit() {
    //   await editData(this.con.id, {
    //     con: this.con.con,
    //     title: this.con.title,
    //     type: this.con.type,
    //     url: this.con.url
    //   }).then(res => {
    //     if (res.code == 200) {
    //       this.$message.success("编辑成功");
    //       this.$emit("outClose");
    //     }
    //   });
    // },
    outClose() {
      this.$emit("outClose");
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .logo_hide .el-upload--picture-card {
  display: none !important;
}
/deep/ .el-checkbox.is-bordered.el-checkbox--medium {
  margin-right: 0px;
}
/deep/ .el-dialog__body {
  padding: 0px 20px;
}
/deep/ .el-dialog__header {
  padding: 10px 10px 10px;
  border-bottom: 1px solid #f5f6f7;
}
/deep/ .el-dialog__headerbtn {
  top: 15px;
}

/deep/ .el-upload--picture-card {
  width: 110px !important;
  height: 110px !important;
  line-height: 110px !important;
  margin-left: 5;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 110px !important;
  height: 110px !important;
  line-height: 110px !important;
}

/deep/ .item .list .el-input[data-v-3f61525b],
.item .list .el-textarea[data-v-3f61525b] {
  width: 300px !important;
  margin-right: 20px;
}

/deep/ .avatar {
  width: 100%;
  height: 100%;
  display: block;
}

/* 弹出框居中显示 */
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
}
/* 弹出框超出部分滑动 */
/deep/ .el-dialog__body {
  overflow: hidden;
  overflow-y: auto;
}
/deep/ .el-radio {
  margin-right: 0px;
}

/deep/ .el-select .el-input {
  width: 100%;
}
.list-data {
  width: 100%;
  margin-top: 20px;
  h3 {
    width: 100%;
    height: 45px;
    line-height: 45px;
    background: #f57a3d;
    color: #fff;
    border-radius: 3px;
    text-indent: 10px;
    font-size: 15px;
    margin-bottom: 20px;
  }
  .list-dialog {
    width: 95%;
    margin: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .list {
      width: 310px;
      margin-bottom: 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        width: 100%;
      }
      .el-date-picker {
        width: 100%;
      }
    }
  }
}

.up_list {
  width: 95%;
  display: flex;
  margin: auto;
  margin-bottom: 20px;
  p {
    display: flex;
    margin-right: 20px;
    span {
      width: 10px;
      font-size: 15px;
      color: #000;
      opacity: 0.9;
      display: block;
    }
  }
}
.itemIms {
  width: auto;
  display: flex;
  .img_list {
    width: 110px;
    height: 110px;
    margin-right: 10px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    p {
      position: absolute;
      width: 100%;
      height: 100%;
      line-height: 110px;
      background: rgba(0, 0, 0, 0.8);
      top: 0;
      color: #fff;
      text-align: center;
      cursor: pointer;
      opacity: 0;
      transition: 0.3s;
      border-radius: 5px;
      text-align: center;
      display: block;
      font-size: 20px;
    }
  }
  .img_list:hover p {
    opacity: 1;
  }
}

.code {
  width: 95%;
  height: 35px;
  line-height: 35px;
  margin: auto;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 600;
  text-align: right;
  span {
    color: #409eff;
    cursor: pointer;
  }
}
</style>