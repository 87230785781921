import request from '@/utils/request'

export function getList(type) {
    return request({
        url: '/data/getList?type=' + type,
        method: 'get'
    })
}

export function getMoreList() {
    return request({
        url: '/data/getMoreList',
        method: 'get'
    })
}

export function getLevelList(data) {
    return request({
        url: '/data/getLevelList?f_name=' + data,
        method: 'get'
    })
}
