<template>
  <div class="page">
    <div class="content">
      <div class="left" v-show="isShow">
        <i class="iconfont icon-guanbi" @click="isShow = false"></i>
        <v-broadside :data="datas" :navList="navList" />
      </div>
      <div class="right">
        <v-header @operation="operation" />
        <v-data :data="datas" />
      </div>
    </div>

    <v-footer />
  </div>
</template>

<script>
// import axios from "axios";
import header from "./components/header.vue";
import footer from "@/components/footer.vue"
import data from "./components/data.vue";
import broadside from "./components/broadside.vue";
import { getList } from "@/api/data";
export default {
  components: {
    "v-header": header,
    "v-data": data,
    "v-broadside": broadside,
    "v-footer": footer
  },
  data() {
    return {
      datas: [],
      baidu: [],
      search: [],
      navList: [],
      searchInfo: {},
      kr: [],
      isShow: false
    };
  },
  watch: {
    isShow(data) {
      if (data) {
        getList(1).then(res => {
          this.navList = res.data;
        });
      }
    }
  },
  created() {
    getList(0).then(res => {
      this.datas = res.data;
    });
  },
  methods: {
    operation() {
      if (this.isShow) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.page {
  background: #f5f6f7;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  .left {
    width: 420px;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    left: 0;
    z-index: 9;
    position: absolute;
    left: 0;
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 30px;
      color: #fff;
      cursor: pointer;
    }
  }
  .right {
    width: 100%;
    overflow-y: scroll;
  }

  ::-webkit-scrollbar {
    width: 3px;
    height: 10px;
    background-color: rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}
// .banner {
//   width: 100%;
//   height: 330px;
//   background: url("http://guozhivip.com/images/bg/b22.jpg")
//     no-repeat;
//   background-size: cover;
//   position: relative;
//   .gradient-linear {
//     background-attachment: fixed;
//     background-image: radial-gradient(
//         rgba(0, 0, 0, 0) 0%,
//         rgba(0, 0, 0, 0.1) 100%
//       ),
//       radial-gradient(rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.1) 166%),
//       linear-gradient(
//         180deg,
//         rgba(0, 0, 0, 0) 0%,
//         rgba(0, 0, 0, 0) 0% 75%,
//         rgba(0, 0, 0, 0) 0%,
//         rgba(0, 0, 0, 0.4) 100%
//       );
//     background-position-y: top;
//     background-repeat: no-repeat;
//     background-size: cover;
//     height: 100%;
//     opacity: 1;
//     position: absolute;
//     transition: opacity 0.3s linear;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
//   .s-search {
//     width: 800px;
//     height: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     margin: auto;
//     position: relative;
//     .select {
//       display: flex;
//       margin-top: 25px;
//       .list {
//         width: auto;
//         padding: 25px 20px;
//         .list-p {
//           color: rgba(225, 225, 225, 0.8);
//           font-size: 16px;
//           cursor: pointer;
//         }

//         .list-p-xz {
//           color: #fff;
//           position: relative;
//           font-weight: 800;
//           &::after {
//             content: "";
//             position: absolute;
//             width: 30px;
//             height: 4px;
//             background: #fff;
//             border-radius: 10px;
//             left: 50%;
//             transform: translateX(-50%);
//             bottom: -10px;
//           }
//         }
//       }
//     }
//     ul {
//       display: flex;
//       margin-top: 25px;
//       li {
//         list-style: none;
//         margin-left: 10px;
//         margin-right: 10px;
//         a {
//           text-decoration: none;
//           color: rgba(225, 225, 225, 0.9);
//           font-weight: 800;
//           font-size: 15px;
//         }
//       }
//     }
//     /deep/ .el-input__inner {
//       height: 50px;
//       backdrop-filter: blur(5px);
//       background-color: rgba(0, 0, 0, 0.55);
//       border: none;
//     }
//     /deep/ .el-input-group__append,
//     .el-input-group__prepend {
//       background-color: rgba(0, 0, 0, 0.55);
//       border: none;
//       color: #fff;
//       font-size: 20px;
//       font-weight: 800;
//     }
//   }
// }
// .content {
//   width: 1300px;
//   margin: 20px auto;
//   .item {
//     width: 150px;
//     height: auto;
//     background: #fff;
//     box-shadow: 0 0 2rem 0 var(rgba(41, 48, 66, 0.1), transparent) !important;
//     border-radius: 3px;
//     padding-top: 10px;
//     padding-bottom: 10px;
//     float: left;
//     .list {
//       width: 90%;
//       height: 40px;
//       line-height: 40px;
//       margin: auto;
//       p {
//         font-size: 14px;
//         i {
//           font-size: 17px;
//           margin-left: 10px;
//           margin-right: 10px;
//         }
//       }
//     }
//   }
//   .data {
//     width: 825px;
//     float: left;
//     margin-left: 13px;
//     .nav {
//       height: 30px;
//       line-height: 30px;
//       padding-top: 5px;
//       margin-bottom: 15px;
//       display: flex;
//       align-items: center;
//       h3 {
//         width: 100px;
//         font-size: 19px;
//         font-weight: 600;
//         position: relative;
//         margin-right: 15px;
//         &::after {
//           position: absolute;
//           content: "";
//           width: 2px;
//           height: 15px;
//           right: 5px;
//           top: 50%;
//           transform: translateY(-50%);
//           background: #000;
//           opacity: 0.3;
//         }
//       }
//       .select {
//         display: flex;
//         font-size: 12px;
//         margin-top: 2px;
//         p {
//           font-size: 14px;
//           margin-right: 30px;
//           color: #888;
//           cursor: pointer;
//         }
//       }
//     }
//     &-item {
//       width: 100%;
//       // box-shadow: 0 0 10px 0 var(#ccc,transparent) !important;
//       border-radius: 3px;
//       .data-list {
//         padding: 10px 20px;
//         margin-bottom: 13px;
//         background: #fff;
//         border-radius: 3px;
//         ul {
//           display: flex;
//           flex-wrap: wrap;
//           li {
//             width: 20%;
//             list-style: none;
//             margin-top: 8px;
//             margin-bottom: 5px;
//             a {
//               text-decoration: none;
//               color: #000;
//               opacity: 0.7;
//               font-size: 14px;
//               display: flex;
//               text-indent: 5px;
//               justify-content: space-between;
//               i {
//                 display: block;
//                 margin-right: 80px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }

//   .right {
//     width: 300px;
//     height: 500px;
//     border-radius: 3px;
//     float: right;
//     .box {
//       width: 95%;
//       margin: auto;
//       background: #fff;
//       padding: 15px 15px;
//       margin-bottom: 10px;
//       ul {
//         list-style: none;
//         margin-top: 5px;
//         li {
//           height: 28px;
//           line-height: 30px;
//         }
//         a {
//           width: 100%;
//           text-decoration: none;
//           color: #444d58;
//           opacity: 0.95;
//           font-size: 13px;
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           transition: 0.3s;
//           font-weight: 400;
//           em {
//             font-family: Constantia, Georgia, sans-serif;
//             font-size: 15px;
//             color: #999;
//           }
//           span {
//             width: 200px;
//             text-overflow: ellipsis;
//             overflow: hidden;
//             white-space: nowrap;
//             display: block;
//             margin-right: 10px;
//           }

//           p {
//             color: #999;
//           }
//           &:hover span {
//             text-decoration: underline;
//             color: #0084ff;
//           }
//         }
//       }
//       .tag {
//         display: flex;
//         align-items: center;
//         margin-top: 10px;
//         padding-bottom: 6px;
//         border-bottom: 1px solid rgba(0, 0, 0, 0.09);
//         i {
//           font-size: 12px;
//           color: rgba(0, 0, 0, 0.4);
//           margin-right: 5px;
//         }
//         p {
//           font-size: 12px;
//           color: rgba(0, 0, 0, 0.4);
//         }
//       }
//     }
//   }
// }
</style>