<template>
  <div>
    <div class="logo" @click="flexible()">福利之家导航</div>
    <ul>
      <li v-for="(list,index) in navList" :key="index">
        <h3>{{list.name}}</h3>
        <a v-for="(item,key) in list.lists" :key="key" :href="item.url" target="_blank">{{item.name}}</a>
      </li>

      <!-- <li>
        <h3></h3>
        <a href=""></a>
      </li>-->
    </ul>
  </div>
</template>

<script>
export default {
  props: ["data","navList"],
  data() {
    return {
      list: [
        "综合门户",
        "影视娱乐",
        "建筑金融",
        "信息资讯",
        "医学健康",
        "软件站点",
        "软件下载",
        "设计素养",
        "管理部门",
        "在线教程",
        "地图工具",
        "项目资讯",
        "站点博客",
        "规范查询",
        "内容作者",
        "图片灵感",
        "信息数据",
        "资料检索",
        "考研资讯"
      ]
    };
  },
  methods: {
    flexible() {}
  }
};
</script>

<style lang="less" scoped>
.logo {
  width: 100%;
  height: 55px;
  border-bottom: 1px solid rgba(225,225,225,0.2);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
ul {
  margin-top: 15px;
  li {
    width: 95%;
    list-style: none;
    margin-bottom: 18px;
    cursor: pointer;
    flex-direction: column;
    margin: auto;
    h3 {
      width: 95%;
      margin: auto;
      color: #fff;
    }
    a {
      box-sizing: border-box;
      float: left;
      width: 21%;
      margin: 2%;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 5px;
      transition: 0.2s all linear;
      color: #fff;
      background: #2935509e;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
    }
  }
}
</style>
